import React, { useContext, useState, useEffect } from 'react'
import Context from '../context'
import PropTypes from 'prop-types'
import { platform, IOS } from '@vkontakte/vkui'
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel'
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader'
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton'
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back'
import Icon24Back from '@vkontakte/icons/dist/24/back'

const osName = platform()

const EditRoute = ({id}) => {
  const { state, dispatch, goBack, connect } = useContext(Context)

  return (
    <Panel id={id}>
			<PanelHeader
				left={<PanelHeaderButton onClick={goBack}>
					{osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
				</PanelHeaderButton>}
			>
        Новое направление
      </PanelHeader>

    </Panel>
  )
}

EditRoute.propTypes = {
	id: PropTypes.string.isRequired
}

export default EditRoute
