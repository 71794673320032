import React, { useContext } from 'react'
import Context from '../context'
import PropTypes from 'prop-types'
import { platform, IOS } from '@vkontakte/vkui'
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel'
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader'
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton'
import Group from '@vkontakte/vkui/dist/components/Group/Group'
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell'
import CellButton from '@vkontakte/vkui/dist/components/CellButton/CellButton'
import Counter from '@vkontakte/vkui/dist/components/Counter/Counter'
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back'
import Icon24Back from '@vkontakte/icons/dist/24/back'
import Icon24Add from '@vkontakte/icons/dist/24/add'

const osName = platform()

const Routes = ({id}) => {
  const { state, dispatch, goBack, goAddRoute, goEditRoute, connect } = useContext(Context)

  return (
    <Panel id={id}>
			<PanelHeader
				left={<PanelHeaderButton onClick={goBack}>
					{osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
				</PanelHeaderButton>}
			>
        Настройка направлений
      </PanelHeader>
      {(state.routes.length !== 0) &&
			<Group title="Направления">
				{ state.routes.filter(r => r.trips).map((r, i) =>
				<Cell key={i} onClick={() => goEditRoute(r)} indicator={<Counter>{ r.trips }</Counter>}>
					{ r.from_name } → { r.to_name }
				</Cell>)}
			</Group>}
      <Group>
        <CellButton before={<Icon24Add />} onClick={goAddRoute}>Добавить направление</CellButton>
      </Group>
    </Panel>
  )
}

Routes.propTypes = {
	id: PropTypes.string.isRequired
}

export default Routes
