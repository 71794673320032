import React, { useState, useEffect, useReducer } from 'react'
import Context from './context'
import reducer from './reducer/'
import io from 'socket.io-client'
import axios from 'axios'
import connect from '@vkontakte/vk-bridge'
import View from '@vkontakte/vkui/dist/components/View/View'
import Alert from '@vkontakte/vkui/dist/components/Alert/Alert'
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner'
import '@vkontakte/vkui/dist/vkui.css'
import Main from './panels/Main'
import Trips from './panels/Trips'
import Add from './panels/Add'
import Edit from './panels/Edit'
import Routes from './panels/Routes'
import AddRoute from './panels/AddRoute'
import EditRoute from './panels/EditRoute'
const  socket = io()

const App = () => {
	const [state, dispatch] = useReducer(reducer, {
		params: null,
		user: null,
		edit: null,
		trips: null,
		mytrips: [],
		rtrips: [],
		route: null,
		routes: [],
		cities: [],
		token: window.localStorage.getItem('token'),
		phone: window.localStorage.getItem('phone')
	})
	const [activePanel, setActivePanel] = useState('main')
	const [popout, setPopout] = useState(<ScreenSpinner />)
  const [ activeModal, setActiveModal ] = useState()
	const [times] = useState([,
		'00:00',
		'00:30',
		'01:00',
		'01:30',
		'02:00',
		'02:30',
		'03:00',
		'03:30',
		'04:00',
		'04:30',
		'05:00',
		'05:30',
		'06:00',
		'06:30',
		'07:00',
		'07:30',
		'08:00',
		'08:30',
		'09:00',
		'09:30',
		'10:00',
		'10:30',
		'11:00',
		'11:30',
		'12:00',
		'12:30',
		'13:00',
		'13:30',
		'14:00',
		'14:30',
		'15:00',
		'15:30',
		'16:00',
		'16:30',
		'17:00',
		'17:30',
		'18:00',
		'18:30',
		'19:00',
		'19:30',
		'20:00',
		'20:30',
		'21:00',
		'21:30',
		'22:00',
		'22:30',
		'23:00',
		'23:30'
	])

	useEffect(() => {
		connect.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme')
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light'
				document.body.attributes.setNamedItem(schemeAttribute)
			}
			if (type === 'VKWebAppViewRestore') {
				fetchData()
			}
			if (type === 'VKWebAppCallAPIMethodResult') {
				if (data.response) {
					dispatch({ type: 'SET_CITIES', payload: data.response.items })
				}
				else {
					dispatch({ type: 'SET_CITIES', payload: [] })
				}
				
			}
			if (type === 'VKWebAppAccessTokenReceived') {
				if (data.access_token) {
					dispatch({ type: 'SET_TOKEN', payload: data.access_token })
				}
			}
		})
		state.token || connect.send('VKWebAppGetAuthToken', {scope: '', app_id: process.env.NODE_ENV === 'development' ?	7243197 : 7156919})
		socket.on('connect', () => {
			fetchData()
		})
		socket.on('disconnect', () => {
			
		})
		socket.on('UPDATE', res => {
			dispatch({ type: 'SET_ROUTES', payload: res.routes })
			dispatch({ type: 'SET_TRIPS', payload: res.trips })
		})
		
		async function fetchData() {
			let params = window.location.search.slice(1).split('&')
				.map(queryParam => {
					let kvp = queryParam.split('=')
					return {key: kvp[0], value: kvp[1]}
				})
				.reduce((query, kvp) => {
					query[kvp.key] = kvp.value
					return query
				}, {})
			let localStorageParams = JSON.parse(window.localStorage.getItem('params'))
			if (params.vk_group_id || localStorageParams) {
				if (params.vk_group_id) {
					window.localStorage.setItem('params', JSON.stringify(params))
				}
				if (localStorageParams && !params.vk_group_id) {
					params = localStorageParams
				}
				socket.emit('INIT', params)
				const info = await connect.sendPromise('VKWebAppGetUserInfo')
				dispatch({ type: 'SET_USER', payload: info })
				axios.post('/api/init/', params).then(res => {
					dispatch({ type: 'SET_PARAMS', payload: params })
					if (res.data.routes) {
						dispatch({ type: 'SET_ROUTES', payload: res.data.routes })
					}
					if (res.data.trips) {
						dispatch({ type: 'SET_TRIPS', payload: res.data.trips })
					}
					setPopout(null)
					if (res.data.error) {
						setPopout(<Alert
							actions={[{
								title: 'Закрыть',
								autoclose: true
							}]}
							onClose={() => window.history.back()}
						>
							<h2>Ошибка!</h2>
						<p>{ res.data.error }</p>
						</Alert>)
					}
				})
			}
			else {
				setPopout(<Alert
					actions={[{
						title: 'Закрыть',
						autoclose: true
					}]}
					onClose={() => window.history.back()}
				>
					<h2>Ошибка!</h2>
					<p>Приложение запущено не из сообщества.</p>
				</Alert>)
			}
		}
	}, [])

	useEffect(() => {
		window.onpopstate = e => {
			e.preventDefault()
			let pathname = window.location.pathname.split('/').join('')
			setActivePanel(!pathname || pathname === '' ? 'main' : pathname)
		}
	}, [])

	const goBack = () => {
		setActiveModal(null)
		window.history.back()
	}

	const goMain = () => {
		setActivePanel('main')
		window.history.replaceState({}, null, '/')
	}

	const goAdd = () => {
		if (state.mytrips.length === 2) {
			setPopout(<Alert
				actions={[{
					title: 'Закрыть',
					autoclose: true
				}]}
				onClose={() => setPopout(null)}
			>
				<h2>Ограничение!</h2>
				<p>Можно предложить не более 2х поездок одновременно.</p>
			</Alert>)
		}
		else {
			setActivePanel('add')
			window.location.pathname === '/trips/' || window.history.pushState({}, null, '/add/')
		}
	}

	const goEdit = (item) => {
		setActiveModal(null)
		dispatch({ type: 'SET_EDIT', payload: item })
		setActivePanel('edit')
		window.history.pushState({}, null, '/edit/')
	}

	const viewTrips = r => {
		dispatch({ type: 'SET_ROUTE', payload: r })
		if (window.location.pathname === '/trips/') {
			setActivePanel('trips')
			window.history.replaceState({}, null, '/trips/')
		}
		else if (window.location.pathname === '/add/') {
			setActivePanel('main')
			window.history.replaceState({}, null, '/')
		}
		else if (window.location.pathname === '/edit/') {
			goBack()
		}
		else if (window.location.pathname === '/') {
			setActivePanel('trips')
			window.history.pushState({}, null, '/trips/')
		}
	}

	const emitTrip = (action, data) => {
		setPopout(<ScreenSpinner />)
		axios.post('/api/' + action + '/', data).then(res => {
			if (res.data.routes) {
				dispatch({ type: 'SET_ROUTES', payload: res.data.routes })
			}
			if (res.data.trips) {
				dispatch({ type: 'SET_TRIPS', payload: res.data.trips })
			}
			viewTrips(state.route)
			setPopout(null)
		})
	}

  const deleteTrip = item => {
		setActiveModal(null)
    let data = {
			id: item.id,
      group_id: Number(state.params.vk_group_id)
    }
		setPopout(<ScreenSpinner />)
		axios.post('/api/delete/', data).then(res => {
			if (res.data.routes) {
				dispatch({ type: 'SET_ROUTES', payload: res.data.routes })
			}
			if (res.data.trips) {
				dispatch({ type: 'SET_TRIPS', payload: res.data.trips })
			}
			setPopout(null)
		})
	}
	
	const goRoutes = () => {
		setActivePanel('routes')
		window.history.pushState({}, null, '/routes/')
	}

	const goAddRoute = () => {
		setActivePanel('addroute')
		window.history.pushState({}, null, '/addroute/')
	}

	const goEditRoute = r => {
		dispatch({ type: 'SET_ROUTE', payload: r })
		setActivePanel('editroute')
		window.history.pushState({}, null, '/editroute/')
	}

	return (
		<Context.Provider value={{ state, dispatch, socket, connect, goBack, viewTrips, goMain, goRoutes, goAddRoute, goEditRoute, goAdd, goEdit, emitTrip, deleteTrip, times, setActiveModal }}>
			<View activePanel={activePanel} popout={popout} modal={activeModal} onSwipeBack={goBack}>
				<Main id='main' />
				<Trips id='trips' />
				<Add id='add' />
				<Edit id='edit' />
				<Routes id='routes' />
				<AddRoute id='addroute' />
				<EditRoute id='editroute' />
			</View>
		</Context.Provider>
	)
}

export default App
