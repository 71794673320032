import React, { useContext, useState, useEffect } from 'react'
import Context from '../context'
import PropTypes from 'prop-types'
import { platform, IOS } from '@vkontakte/vkui'
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel'
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader'
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton'
import FixedLayout from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout'
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout'
import FormLayoutGroup from '@vkontakte/vkui/dist/components/FormLayoutGroup/FormLayoutGroup'
import SelectMimicry from '@vkontakte/vkui/dist/components/SelectMimicry/SelectMimicry'
import ModalRoot from '@vkontakte/vkui/dist/components/ModalRoot/ModalRoot'
import ModalPage from '@vkontakte/vkui/dist/components/ModalPage/ModalPage'
import ModalPageHeader from '@vkontakte/vkui/dist/components/ModalPageHeader/ModalPageHeader'
import Group from '@vkontakte/vkui/dist/components/Group/Group'
import List from '@vkontakte/vkui/dist/components/List/List'
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell'
import Div from '@vkontakte/vkui/dist/components/Div/Div'
import Tabs from '@vkontakte/vkui/dist/components/Tabs/Tabs'
import TabsItem from '@vkontakte/vkui/dist/components/TabsItem/TabsItem'
import Button from '@vkontakte/vkui/dist/components/Button/Button'
import Input from '@vkontakte/vkui/dist/components/Input/Input'
import Link from '@vkontakte/vkui/dist/components/Link/Link'
import Select from '@vkontakte/vkui/dist/components/Select/Select'
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back'
import Icon24Back from '@vkontakte/icons/dist/24/back'
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel'
import Icon24Done from '@vkontakte/icons/dist/24/done'
import moment from 'moment'

const osName = platform()

const Add = ({id}) => {
  const { state, dispatch, goBack, connect, emitTrip, times, setActiveModal } = useContext(Context)
  const now = moment().format('DD.MM.YYYY')
  const dates = [
    {
      value: now,
      text: 'Сегодня'
    },
    {
      value: moment().add(1, 'day').format('DD.MM.YYYY'),
      text: 'Завтра'
    },
    {
      value: moment().add(2, 'day').format('DD.MM.YYYY'),
      text: moment().add(2, 'day').format('D MMMM')
    },
    {
      value: moment().add(3, 'day').format('DD.MM.YYYY'),
      text: moment().add(3, 'day').format('D MMMM')
    }
  ]
  const [ validPhone, setValidPhone ] = useState(true)
  const [ phone, setPhone ] = useState(state.phone)
  const [ prices, setPrices ] = useState([])
  const [ price, setPrice ] = useState(0)
  const [ places, setPlaces ] = useState(4)
  const [ type, setType ] = useState('driver')
  const [ time, setTime ] = useState(moment().add(1, 'hour').format('HH') + ':00')
  const [ date, setDate ] = useState(now)
  const [ timesSelect, setTimesSelect ] = useState(times)
  const [ routes ] = useState(state.mytrips[0] ? state.routes.filter(r => !(r.from_id === state.mytrips[0].from_id && r.to_id === state.mytrips[0].to_id)) : state.routes)
  const [ activeRoute, setActiveRoute ] = useState(null)
  
  const getPhone = () => {
    connect.sendPromise('VKWebAppGetPhoneNumber', {})
      .then(res => {
        setPhone(res.phone_number)
        dispatch({ type: 'SET_PHONE', payload: res.phone_number })
      })
  }
  
  useEffect(() => {
    setActiveRoute(routes[0])
  }, [routes])

  useEffect(() => {
    type === 'driver' ? setPlaces(4) : setPlaces(1)
  }, [type])

  useEffect(() => {
    if (routes.length && activeRoute) {
      setPrices([...new Array((activeRoute.offset*2)/50 + 1)].map((_, i) => activeRoute.price - activeRoute.offset + i*50))
      setPrice(activeRoute.price)
    }
  }, [activeRoute, routes])

  useEffect(() => {
    if (date === now) {
      let h = moment().add(1, 'hour').format('HH')
      Number(time.substr(0, 2)) && Number(h) > Number(time.substr(0, 2)) && setTime(h + ':00')
      setTimesSelect(times.filter(tt => (Number(h) && Number(h) <= Number(tt.substr(0, 2)))))
    }
    else {
      setTimesSelect(times)
    }
  }, [date, time])

  const inputPhone = val => {
    setValidPhone(true)
    let number = val.replace(/[^0-9]/g, '')
    if (!phone || phone === '') {
      number === '9' ? setPhone('79') : setPhone('7')
    }
    else {
      if (number.length < 12) {
        if (number.length === 2 && number !== '79') {
          setPhone('79')
        }
        else {
          setPhone(number)
        }
      }
    }
  }

  const addTrip = () => {
    let data = {
      group_id: Number(state.params.vk_group_id),
      user_id: state.user.id,
      first_name: state.user.first_name,
      last_name: state.user.last_name,
      photo: state.user.photo_max_orig,
      photo_100: state.user.photo_100,
      photo_200: state.user.photo_200,
      type,
      datetime: moment(date + ' ' + time, 'DD.MM.YYYY H:mm').format(),
      date: date,
      time: time,
      price,
      places,
      from_id: activeRoute.from_id,
      to_id: activeRoute.to_id,
      phone: phone
    }
    
    if ( phone && phone.length === 11 ) {
      dispatch({ type: 'SET_PHONE', payload: phone })
      emitTrip('add', data)
    }
    else {
      setValidPhone(false)
    }
  }

  const declOfNum = num => {
    let p = type === 'driver' ? [num + ' место', num + ' места', num + ' мест'] : [num + ' пассажир', num + ' пассажира', num + ' пассажиров']
    return p[ (num%100>4 && num%100<20)? 2 : [2, 0, 1, 1, 1, 2][(num%10<5)?num%10:5] ]
  }

  const setModal = modal  => {
    setActiveModal(
      <ModalRoot activeModal={modal}>
        <ModalPage
          id="routes"
          onClose={() => setActiveModal(null)}
          header={
            <ModalPageHeader
            right={<PanelHeaderButton onClick={() => setActiveModal(null)}>
              <Icon24Cancel/>
            </PanelHeaderButton>}
          >
              Направления
            </ModalPageHeader>
          }
        >
          <Group style={{ paddingBottom: 46 }}>
            <List>
              {routes && routes.map(route => (
              <Cell
                key={route.from_id + '-' + route.to_id}
                onClick={() => {
                  setActiveRoute(route)
                  setActiveModal(null)
                }}
                asideContent={activeRoute && activeRoute.from_id === route.from_id && activeRoute.to_id === route.to_id ? <Icon24Done fill="var(--accent)" /> : null}
              >
                { route.from_name + ' → ' + route.to_name }
              </Cell>))}
            </List>
          </Group>
        </ModalPage>
      </ModalRoot>
    )
  }

  return (
    <Panel id={id}>
			<PanelHeader
				left={<PanelHeaderButton onClick={goBack}>
					{osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
				</PanelHeaderButton>}
			>
        Новая поездка
      </PanelHeader>
      <FormLayout>
        <FormLayoutGroup>
          <Tabs>
            <TabsItem
              onClick={() => setType('driver')}
              selected={type === 'driver'}
            >Вы водитель</TabsItem>
            <TabsItem
              onClick={() => setType('passenger')}
              selected={type === 'passenger'}
            >Вы пассажир</TabsItem>
          </Tabs>
        </FormLayoutGroup>
        <SelectMimicry
          top="Выберите направление"
          placeholder="Не выбрано"
          onClick={() => setModal('routes')}
        >{ activeRoute && activeRoute.from_name + ' → ' + activeRoute.to_name }</SelectMimicry>
        <FormLayoutGroup
          top="Дата и время поездки"
        >
          <Div style={{display: 'flex', padding: 0}}>
            <Select
              onChange={e => setDate(e.target.value)}
              value={date}
              style={{width:'calc(50% - 24px)'}}
            >
              {dates.map((d, i) =>
              <option key={i} value={d.value}>{d.text}</option>)}
            </Select>
            <Select
              onChange={e => setTime(e.target.value)}
              value={time}
              style={{width:'calc(50% - 24px)'}}
            >
              {timesSelect.map((t, i) =>
              <option key={i} value={t}>{t}</option>)}
            </Select>
          </Div>
        </FormLayoutGroup>
        <FormLayoutGroup
        top="Стоимость и места"
        >
          <Div style={{display: 'flex', padding: 0}}>
            {activeRoute &&
            <Select
              onChange={e => setPrice(e.target.value)}
              value={price}
              style={{width:'calc(50% - 24px)'}}
            >
              {prices.map((p, i) =>
              <option key={i} value={p}>{p + '₽'}</option>)}
            </Select>}
            <Select
              onChange={e => setPlaces(e.target.value)}
              value={places}
              style={{width:'calc(50% - 24px)'}}
            >
              {[1,2,3,4,5,6].map((p, i) =>
              <option key={i} value={p}>{declOfNum(p)}</option>)}
            </Select>
          </Div>
        </FormLayoutGroup>
        <Input
          top="Мобильный телефон"
          type="tel"
          value={phone ? '+' + phone : ''}
          onChange={e => inputPhone(e.target.value)}
          status={validPhone || 'error'}
          bottom={state.phone ? <Link onClick={getPhone}>Обновить номер из профиля VK</Link> : <Link onClick={getPhone}>+ Добавить номер из профиля VK</Link>}
        />
        <br/><br/>
        </FormLayout>
      
      <FixedLayout vertical="bottom">
        <Div>
          <Button size="xl" onClick={addTrip}>Отправить</Button>
        </Div>
      </FixedLayout>
    </Panel>
  )
}

Add.propTypes = {
	id: PropTypes.string.isRequired
}

export default Add
