import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const declOfNum = (num, type) => {
  let p = type === 'driver' ? [num + ' место', num + ' места', num + ' мест'] : [num + ' пассажир', num + ' пассажира', num + ' пассажиров']
  return p[ (num%100>4 && num%100<20)? 2 : [2, 0, 1, 1, 1, 2][(num%10<5)?num%10:5] ]
}

export default (state, action) => {
  switch (action.type) {
    case 'SET_PARAMS':
      return {
        ...state,
        params: action.payload
      }
    case 'SET_USER':
      return {
        ...state,
        user: action.payload
      }
    case 'SET_ROUTES':
      return {
        ...state,
        routes: action.payload
      }
    case 'SET_ROUTE':
      return {
        ...state,
        route: action.payload
      }
    case 'SET_EDIT':
      return {
        ...state,
        edit: action.payload
      }
    case 'SET_TRIPS':
      let td = moment().format('DD.MM.YYYY')
      let tm = moment().add(1, 'day').format('DD.MM.YYYY')
      let mytrips = state.params ? action.payload.filter(t => t.user_id == state.params.vk_user_id).map(trip => Object.assign({ placesname: declOfNum(trip.places, trip.type), timetext: trip.date === td ? 'Сегодня в ' + trip.time.replace(/^0/, '') : trip.date === tm ? 'Завтра в ' + trip.time.replace(/^0/, '') : moment(trip.date + ' ' + trip.time, 'DD.MM.YYYY H:mm').format('D MMMM в H:mm')}, trip)) : []
      let rtrips = mytrips.map(m => action.payload.filter(t => t.type !== m.type && t.date === m.date && t.from_id === m.from_id && t.to_id === m.to_id && ((Number(m.time.substr(3, 2)) === 30 && (Number(t.time.substr(0, 2)) === Number(m.time.substr(0, 2)) || Number(t.time.substr(0, 2)) === Number(m.time.substr(0, 2)) + 1)) || (Number(m.time.substr(3, 2)) === 0 && ((Number(t.time.substr(3, 2)) === 30 && Number(t.time.substr(0, 2)) === Number(m.time.substr(0, 2)) - 1) || Number(t.time.substr(0, 2)) === Number(m.time.substr(0, 2)))))).map(trip => Object.assign({ placesname: declOfNum(trip.places, trip.type), timetext: trip.date === td ? 'Сегодня в ' + trip.time.replace(/^0/, '') : trip.date === tm ? 'Завтра в ' + trip.time.replace(/^0/, '') : moment(trip.date + ' ' + trip.time, 'DD.MM.YYYY H:mm').format('D MMMM в H:mm')}, trip)))
      let trips = action.payload.map(trip => Object.assign({ placesname: declOfNum(trip.places, trip.type), timetext: trip.date === td ? 'Сегодня в ' + trip.time.replace(/^0/, '') : trip.date === tm ? 'Завтра в ' + trip.time.replace(/^0/, '') : moment(trip.date + ' ' + trip.time, 'DD.MM.YYYY H:mm').format('D MMMM в H:mm')}, trip))
      rtrips = [].concat(...rtrips)
      return {
        ...state,
        mytrips,
        rtrips,
        trips
      }
    case 'SET_CITIES':
      return {
        ...state,
        cities: action.payload
      }
    case 'SET_TOKEN':
      window.localStorage.setItem('token', action.payload)
      return {
        ...state,
        token: action.payload
      }
    case 'SET_PHONE':
      window.localStorage.setItem('phone', action.payload)
      return {
        ...state,
        phone: action.payload
      }
    default:
      return state
  }
}
