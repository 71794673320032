import React, { useState, useEffect, useContext } from 'react'
import Context from '../context'
import PropTypes from 'prop-types'
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel'
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader'
import Group from '@vkontakte/vkui/dist/components/Group/Group'
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell'
import CellButton from '@vkontakte/vkui/dist/components/CellButton/CellButton'
import List from '@vkontakte/vkui/dist/components/List/List'
import ModalRoot from '@vkontakte/vkui/dist/components/ModalRoot/ModalRoot'
import ModalCard from '@vkontakte/vkui/dist/components/ModalCard/ModalCard'
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar'
import Counter from '@vkontakte/vkui/dist/components/Counter/Counter'
import Tooltip from '@vkontakte/vkui/dist/components/Tooltip/Tooltip'
import Icon24Add from '@vkontakte/icons/dist/24/add'
import Icon24Shuffle from '@vkontakte/icons/dist/24/shuffle'

const Main = ({ id }) => {
	const { state, viewTrips, goRoutes, goAdd, goEdit, deleteTrip, setActiveModal } = useContext(Context)
	const [ item, setItem ] = useState(null)
	const [ tooltip, setTooltip ] = useState(false)

	useEffect(() => {
		setTooltip(false)
		if (state.routes.length > 0) {
			if (!state.mytrips.length) {
				// setTooltip('noMyTrips')
			}
		}
		else {
			setTooltip('noRoutes')
		}
	}, [state])

	useEffect(() => {
    item ? setModal('call') : setActiveModal(null)
  }, [item])

	const setModal = modal  => {
    setActiveModal(
			<ModalRoot activeModal={modal}>
				<ModalCard
					id='call'
					onClose={() => {
						setItem(null)
					}}
					icon={<Avatar src={item.photo_200} size={200} />}
					title={item.first_name + ' ' + item.last_name}
					caption={
						<div>
							<span>{ item.from_name } → { item.to_name }</span>
							<br/>
							<span>{item.timetext }</span>
							<br/>
							<span>{item.placesname}, { item.price }₽</span>
						</div>
					}
					actionsLayout={(state.user.id === 11535193 && item.user_id !== state.user.id) && 'vertical'}
					actions={
						state.user.id === 11535193 && item.user_id !== state.user.id  ?
						[
							{
								title: '+' + item.phone,
								type: 'primary',
								action: () => {
									window.open('tel:+' + item.phone)
								}
							},
							{
								title: 'Удалить',
								type: 'destructive',
								action: () => {
									deleteTrip(item)
								}
							}
						] : item.user_id === state.user.id ?
						[
							{
								title: 'Изменить',
								type: 'primary',
								action: () => {
									goEdit(item)
								}
							},
							{
								title: 'Удалить',
								type: 'destructive',
								action: () => {
									deleteTrip(item)
								}
							}
						]:
						[
							{
								title: '+' + item.phone,
								type: 'primary',
								action: () => {
									window.open('tel:+' + item.phone)
								}
							}
						]
					}
				/>
			</ModalRoot>
		)
	}

	return (
		<Panel id={id}>
			<PanelHeader>Попутчики</PanelHeader>
			{state.mytrips.length > 0 &&
				<Group title="Мои поездки">
					<List>
						{state.mytrips.map(trip => (
						<Cell
							key={trip.id}
							size="l"
							before={<Avatar src={trip.photo_100} size={68} />}
							indicator={ trip.price + '₽' }
							onClick={() => {
								setItem(trip)
							}}
							description={
								<div>
									<span>{ trip.from_name } → { trip.to_name }</span>
									<br/>
									<span>{ trip.timetext }</span>
									<br/>
									<span>{ trip.placesname}</span>
								</div>
							}
						>
							{trip.first_name} {trip.last_name}
						</Cell>))}
					</List>
				</Group>
			}
			{(state.routes.length !== 0) &&
			<Group>
				<Tooltip
					text="Самый простой способ найти попутчиков!"
					isShown={tooltip === 'noMyTrips'}
					onClose={() => setTooltip(false)}
					offsetX={10}
				>
					<CellButton before={<Icon24Add />} onClick={goAdd}>Предложить поездку</CellButton>
				</Tooltip>
			</Group>}
			{(state.routes.filter(r => r.trips).length !== 0) &&
			<Group title="Направления">
				{ state.routes.filter(r => r.trips).map((r, i) =>
				<Cell key={i} onClick={() => viewTrips(r)} indicator={<Counter>{ r.trips }</Counter>}>
					{ r.from_name } → { r.to_name }
				</Cell>)}
			</Group>}
			{state.params && state.user && state.params.vk_viewer_group_role && (state.params.vk_viewer_group_role === 'moder' || state.params.vk_viewer_group_role === 'editor' || state.params.vk_viewer_group_role === 'admin' || state.user.id === 11535193) &&
			<Group>
				<Tooltip
					text="У вас нет добавленных направлений!"
					isShown={tooltip === 'noRoutes'}
					onClose={() => setTooltip(false)}
					offsetX={10}
				>
					<CellButton before={<Icon24Shuffle />} onClick={goRoutes}>Настройка направлений</CellButton>
				</Tooltip>
			</Group>}
			{state.rtrips.length > 0 &&
				<Group title="Попутчики">
					<List>
						{state.rtrips.map(trip => (
						<Cell
							key={trip.id}
							size="l"
							before={<Avatar src={trip.photo_100} size={68} />}
							indicator={ trip.price + '₽' }
							onClick={() => {
								setItem(trip)
								setActiveModal('call')
							}}
							description={
								<div>
									<span>{ trip.from_name } → { trip.to_name }</span>
									<br/>
									<span>{ trip.timetext }</span>
									<br/>
									<span>{ trip.placesname}</span>
								</div>
							}
						>
							{trip.first_name} {trip.last_name}
						</Cell>))}
					</List>
				</Group>
			}
		</Panel>
	)
}

Main.propTypes = {
	id: PropTypes.string.isRequired
}

export default Main
