import React, { useContext, useState, useEffect } from 'react'
import Context from '../context'
import PropTypes from 'prop-types'
import { platform, IOS } from '@vkontakte/vkui'
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel'
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader'
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton'
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout'
import FormLayoutGroup from '@vkontakte/vkui/dist/components/FormLayoutGroup/FormLayoutGroup'
import SelectMimicry from '@vkontakte/vkui/dist/components/SelectMimicry/SelectMimicry'
import ModalRoot from '@vkontakte/vkui/dist/components/ModalRoot/ModalRoot'
import ModalPage from '@vkontakte/vkui/dist/components/ModalPage/ModalPage'
import ModalPageHeader from '@vkontakte/vkui/dist/components/ModalPageHeader/ModalPageHeader'
import Group from '@vkontakte/vkui/dist/components/Group/Group'
import List from '@vkontakte/vkui/dist/components/List/List'
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell'
import Div from '@vkontakte/vkui/dist/components/Div/Div'
import Tabs from '@vkontakte/vkui/dist/components/Tabs/Tabs'
import TabsItem from '@vkontakte/vkui/dist/components/TabsItem/TabsItem'
import Button from '@vkontakte/vkui/dist/components/Button/Button'
import Input from '@vkontakte/vkui/dist/components/Input/Input'
import Link from '@vkontakte/vkui/dist/components/Link/Link'
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back'
import Icon24Back from '@vkontakte/icons/dist/24/back'
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel'

const osName = platform()

const AddRoute = ({id}) => {
  const { state, dispatch, goBack, connect } = useContext(Context)
  const [ activeModal, setActiveModal ] = useState(null)
  const [ from, setFrom ] = useState(null)
  const [ to, setTo ] = useState(null)

  useEffect(() => {
    activeModal || connect.send('VKWebAppCallAPIMethod', {method: 'database.getCities', params: {need_all: 1, country_id: 1, q: '', v: '5.103', access_token: state.token, count: 10}})
  }, ['activeModal'])
  
  const searchCity = q => {
    connect.send('VKWebAppCallAPIMethod', {method: 'database.getCities', params: {need_all: 1, country_id: 1, q: q, v: '5.103', access_token: state.token, count: 10}})
  }

  const selectCity = city => {
    activeModal === 'from' ? setFrom(city) : setTo(city)
    setActiveModal(null)
  }

  return (
    <Panel id={id}>
			<PanelHeader
				left={<PanelHeaderButton onClick={goBack}>
					{osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
				</PanelHeaderButton>}
			>
        Новое направление
      </PanelHeader>
      <FormLayout>
        <SelectMimicry
          top="Откуда"
          placeholder="Не выбрано"
          onClick={() => setActiveModal('from')}
        >{ from && from.title }</SelectMimicry>
      </FormLayout>
      <FormLayout>
        <SelectMimicry
          top="Куда"
          placeholder="Не выбрано"
          onClick={() => setActiveModal('to')}
        >{ to && to.title }</SelectMimicry>
      </FormLayout>
      <ModalRoot activeModal={activeModal}>
        <ModalPage
          id="from"
          onClose={() => setActiveModal(null)}
          header={
            <ModalPageHeader
            right={<PanelHeaderButton onClick={() => setActiveModal(null)}>
              <Icon24Cancel/>
            </PanelHeaderButton>}
          >
              Откуда
            </ModalPageHeader>
          }
        >
          <FormLayout>
            <Input
              onChange={e => searchCity(e.target.value)}
            />
          </FormLayout>
          <Group style={{ paddingBottom: 46 }}>
            <List>
              {state.cities && state.cities.map(city => (
              <Cell
                key={city.id}
                onClick={() => selectCity(city)}
                description={city.region}
              >
                { city.title }
              </Cell>))}
            </List>
          </Group>
        </ModalPage>
        <ModalPage
          id="to"
          onClose={() => setActiveModal(null)}
          header={
            <ModalPageHeader
            right={<PanelHeaderButton onClick={() => setActiveModal(null)}>
              <Icon24Cancel/>
            </PanelHeaderButton>}
          >
              Куда
            </ModalPageHeader>
          }
        >
          <FormLayout>
            <Input
              onChange={e => searchCity(e.target.value)}
            />
          </FormLayout>
          <List>
            {state.cities && state.cities.map(city => (
            <Cell
              key={city.id}
              onClick={() => selectCity(city)}
              description={city.region}
            >
              { city.title }
            </Cell>))}
          </List>
        </ModalPage>
      </ModalRoot>
    </Panel>
  )
}

AddRoute.propTypes = {
	id: PropTypes.string.isRequired
}

export default AddRoute
