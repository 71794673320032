import React, { useState, useEffect, useContext } from 'react'
import Context from '../context'
import PropTypes from 'prop-types'
import { platform, IOS } from '@vkontakte/vkui'
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel'
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader'
import PanelHeaderContent from '@vkontakte/vkui/dist/components/PanelHeaderContent/PanelHeaderContent'
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton'
import PanelHeaderContext from '@vkontakte/vkui/dist/components/PanelHeaderContext/PanelHeaderContext'
import Group from '@vkontakte/vkui/dist/components/Group/Group'
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell'
import CellButton from '@vkontakte/vkui/dist/components/CellButton/CellButton'
import List from '@vkontakte/vkui/dist/components/List/List'
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar'
import FixedLayout from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout'
import Tabs from '@vkontakte/vkui/dist/components/Tabs/Tabs'
import TabsItem from '@vkontakte/vkui/dist/components/TabsItem/TabsItem'
import Counter from '@vkontakte/vkui/dist/components/Counter/Counter'
import ModalRoot from '@vkontakte/vkui/dist/components/ModalRoot/ModalRoot'
import ModalCard from '@vkontakte/vkui/dist/components/ModalCard/ModalCard'
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back'
import Icon24Back from '@vkontakte/icons/dist/24/back'
import Icon24Add from '@vkontakte/icons/dist/24/add'
import Icon16Dropdown from '@vkontakte/icons/dist/16/dropdown'

const osName = platform()

const Trips = props => {
	const { state, viewTrips, goBack, goAdd, goEdit, deleteTrip, setActiveModal } = useContext(Context)
	const [ view, setView ] = useState(false)
	const [ type, setType ] = useState('driver')
	const [ item, setItem ] = useState(null)
	
	const setRoute = r => {
		viewTrips(r)
		setView(false)
	}

	const declOfNum = (c, titles) => {  
		let cases = [2, 0, 1, 1, 1, 2]
		return c + ' ' + titles[ (c%100>4 && c%100<20)? 2 : cases[(c%10<5)?c%10:5] ]
	}

	useEffect(() => {
    item ? setModal('call') : setActiveModal(null)
	}, [item])

	const setModal = modal  => {
    setActiveModal(
			<ModalRoot activeModal={modal}>
				<ModalCard
					id='call'
					onClose={() => {
						setItem(null)
					}}
					icon={<Avatar src={item.photo_200} size={200} />}
					title={item.first_name + ' ' + item.last_name}
					caption={
						<div>
							<span>{ item.from_name } → { item.to_name }</span>
							<br/>
							<span>{item.timetext }</span>
							<br/>
							<span>{item.placesname}, { item.price }₽</span>
						</div>
					}
					actionsLayout={(state.user.id === 11535193 && item.user_id !== state.user.id) && 'vertical'}
					actions={
						state.user.id === 11535193 && item.user_id !== state.user.id  ?
						[
							{
								title: '+' + item.phone,
								type: 'primary',
								action: () => {
									window.open('tel:+' + item.phone)
								}
							},
							{
								title: 'Удалить',
								type: 'destructive',
								action: () => {
									deleteTrip(item)
								}
							}
						] : item.user_id === state.user.id ?
						[
							{
								title: 'Изменить',
								type: 'primary',
								action: () => {
									goEdit(item)
								}
							},
							{
								title: 'Удалить',
								type: 'destructive',
								action: () => {
									setActiveModal(null)
									deleteTrip(item)
								}
							}
						]:
						[
							{
								title: '+' + item.phone,
								type: 'primary',
								action: () => {
									window.open('tel:+' + item.phone)
								}
							}
						]
					}
				/>
			</ModalRoot>
		)
	}

	return (
		<Panel id={props.id}>
			<PanelHeader
				left={<PanelHeaderButton onClick={goBack}>
					{osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
				</PanelHeaderButton>}
			>
				<PanelHeaderContent
					aside={<Icon16Dropdown />}
					status={ state.route.from_name + ' → ' + state.route.to_name }
					onClick={() => setView(true)}
				>
					Попутчики
				</PanelHeaderContent>
			</PanelHeader>
			<PanelHeaderContext opened={view} onClose={() => setView(!view)}>
				<List>
				{ state.routes.filter(r => r.trips).map((r, i) =>
					<Cell key={i} onClick={() => setRoute(r)} indicator={<Counter type={r.from_id === state.route.from_id && r.to_id === state.route.to_id ? 'primary' : 'secondary'}>{ r.trips }</Counter>}>
						{ r.from_name } → { r.to_name }
					</Cell>)}
				</List>
			</PanelHeaderContext>
			{state.trips &&
			<div style={{ paddingBottom: 50 }}>
				<Group>
					<CellButton onClick={goAdd} before={<Icon24Add />}>Предложить поездку</CellButton>
				</Group>
				<Group>
					<List>
						{state.trips.filter(t => t.type === type && t.from_id === state.route.from_id && t.to_id === state.route.to_id).map(trip => (
						<Cell
							key={trip.id}
							size="l"
							before={<Avatar src={trip.photo_100} size={64} />}
							indicator={ trip.price + '₽' }
							onClick={() => {
								setItem(trip)
							}}
							description={
								<div>
									<span>{trip.timetext }</span>
									<br/>
									<span>{trip.placesname}</span>
								</div>
							}
						>
							{trip.first_name} {trip.last_name}
						</Cell>))}
					</List>
				</Group>
			</div>}
			{state.trips &&
			<FixedLayout vertical="bottom">
				<Tabs>
					<TabsItem
						onClick={() => setType('driver')}
						selected={type === 'driver'}
					>{ declOfNum(state.trips.filter(t => t.type === 'driver' && t.from_id === state.route.from_id && t.to_id === state.route.to_id).length, [' водитель', ' водителя', ' водителей']) }</TabsItem>
					<TabsItem
						onClick={() => setType('passenger')}
						selected={type === 'passenger'}
					>{ declOfNum(state.trips.filter(t => t.type === 'passenger' && t.from_id === state.route.from_id && t.to_id === state.route.to_id).length, [' пассажир', ' пассажира', ' пассажиров']) }</TabsItem>
				</Tabs>
			</FixedLayout>}
		</Panel>
	)
}

Trips.propTypes = {
	id: PropTypes.string.isRequired
}

export default Trips
